import React from "react";
// libs
import Router from "next/router";
// hooks
import { useAppDispatch } from "src/hooks";
// redux
import {
  openModal as openModalReducer,
  closeModal as closeModalReducer,
} from "src/redux/slices/modals";

interface IUseModalsResponse {
  openModal: (modalType: string, modalData?: { [key: string]: any }) => void;
  closeModal: (modalType: string) => void;
}

type TUseModals = () => IUseModalsResponse;

export const useModal: TUseModals = () => {
  const dispatch = useAppDispatch();

  const openModal: IUseModalsResponse["openModal"] = async (type, data) => {
    const openModalState = { type, data };
    dispatch(openModalReducer(openModalState));

    const params = Object.assign({}, Router.router?.query, { open_modal: type });
    await Router.replace(
      {
        pathname: Router.router?.pathname,
        query: params,
      },
      undefined,
      { shallow: true },
    );
  };

  const closeModal: IUseModalsResponse["closeModal"] = async (type) => {
    dispatch(closeModalReducer({ type }));

    const params = { ...Router.router?.query };
    delete params.open_modal;

    await Router.replace(
      {
        pathname: Router.router?.pathname,
        query: params,
      },
      undefined,
      { shallow: true },
    );
  };

  return {
    openModal,
    closeModal,
  };
};
