import getCenter from "geolib/es/getCenter";
// import mapboxgl from 'mapbox-gl'
import { LngLat, LngLatBounds } from "src/constants/coordinates";
import { PLACE_TYPES } from "src/constants/map";
import { getCountry } from "./countries";
import { ICountry } from "../constants/countries";
import { formatSearchZoom } from "./url";

export const getBoundsByCenter = (center: LngLat, zoom: number): LngLatBounds | void => {
  return {
    ne: {
      lng: Number(center.lng - 0.8),
      lat: Number(center.lat + 0.04),
    },
    sw: {
      lng: Number(center.lng + 0.8),
      lat: Number(center.lat - 0.04),
    },
  };

  // // IT WORKS ONLY ON THE CLIENT SIDE
  // if (typeof document === undefined) return
  //
  // mapboxgl.accessToken = process.env.MAPBOX_PUBLIC_ACCESS_TOKEN as string
  //
  // const map = new mapboxgl.Map({
  //   container: document.createElement('div'),
  //   style: 'mapbox://styles/mapbox/streets-v11',
  //   center,
  //   zoom,
  // })
  //
  // const bounds: mapboxgl.LngLatBounds = map.getBounds()
  //
  // map.remove()
  //
  // return {
  //   ne: {
  //     lng: Number(bounds.getNorthEast().lng.toFixed(4)),
  //     lat: Number(bounds.getNorthEast().lat.toFixed(4)),
  //   },
  //   sw: {
  //     lng: Number(bounds.getSouthWest().lng.toFixed(4)),
  //     lat: Number(bounds.getSouthWest().lat.toFixed(4)),
  //   },
  // }
};

export const getCenterByBounds = ({
  ne = null,
  sw = null,
  point = null,
  cityCheck = null,
}: {
  ne?: null | [number, number];
  sw?: null | [number, number];
  point?: null | [number, number];
  cityCheck?: null | boolean;
}): { center: LngLat; zoom: number } | void => {
  // IT WORKS ONLY ON THE CLIENT SIDE
  // if (typeof document === undefined) return
  // console.log(ne, sw, point, cityCheck)
  //
  // mapboxgl.accessToken = process.env.MAPBOX_PUBLIC_ACCESS_TOKEN as string
  //
  // let mapBoxConfig: any
  //
  // if (point) {
  //   mapBoxConfig = {
  //     container: document.createElement('div'),
  //     style: 'mapbox://styles/mapbox/streets-v11',
  //     center: point,
  //     zoom: cityCheck ? 13 : 10,
  //   }
  // } else {
  //   mapBoxConfig = {
  //     container: document.createElement('div'),
  //     style: 'mapbox://styles/mapbox/streets-v11',
  //     bounds: [ne, sw],
  //   }
  // }
  // const map = new mapboxgl.Map(mapBoxConfig)
  //
  // const center = map.getCenter()
  // const zoom = map.getZoom()
  //
  // map.remove()

  if (point) {
    return { center: { lat: point[1], lng: point[0] }, zoom: 12 };
  }

  const center = getCenter([
    {
      lat: ne![1],
      lng: ne![0],
    },
    {
      lat: sw![1],
      lng: sw![0],
    },
  ]);

  if (center === false) {
    throw new Error("Can't find center");
  }

  return { center: { lat: center.latitude, lng: center.longitude }, zoom: 2 };
};

export const getLocationByCenter = async (center: Array<string>) => {
  const geocoder = new google.maps.Geocoder();
  const response = await geocoder.geocode({ location: { lat: +center[0], lng: +center[1] } });
  const city = response?.results?.find((item) => item.types.includes(PLACE_TYPES.ADMIN_AREA_2));
  return city?.formatted_address;
};

export const getZoomByQueryAreaAndLocale = (queryArea: any, locale: string | undefined) => {
  const [state, city, area] = queryArea;
  const country = getCountry(locale, "locale") as ICountry;
  return formatSearchZoom(country.short_name, state, city, area);
};
