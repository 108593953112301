// libs
import { createSelector } from "@reduxjs/toolkit";
// types
import EWhiteList from "src/redux/white-list";
import { RootState } from "src/redux/store";
import { IModalDefaultState, ModalsState } from "./reducer";

type TModalsState = RootState[EWhiteList.MODALS];

const getState = (state: RootState): TModalsState => state[EWhiteList.MODALS];

export const modalSelector = (modalType: string) =>
  createSelector<RootState, TModalsState, IModalDefaultState>(
    getState,
    (state: ModalsState) => state[modalType],
  );
