// libs
import { css } from "styled-components";
// theme
import { colors } from "src/theme";

export const nprogressOverrides = css`
  div#nprogress {
    .spinner,
    .spinner-ssr {
      display: none;
    }

    .bar {
      background-color: ${colors.main};
      height: 3px;
      z-index: 10000;
    }

    .peg {
      box-shadow: none;
    }
  }
`;
