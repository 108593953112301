// libs
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
// const
import * as modalTypes from "src/constants/modals";
// types
import EWhiteList from "src/redux/white-list";

export interface IModalDefaultState {
  isOpen: boolean;
  [key: string]: any;
}

// Define a type for the slice state
export interface ModalsState {
  [key: string]: IModalDefaultState;
}

// Define the initial state using that type
const initialState: ModalsState = {};

Object.keys(modalTypes).forEach((key: string) => {
  initialState[key] = { isOpen: false };
});

interface IPayloadAction {
  type: string;
  data?: { [key: string]: any };
}

export const modalsSlice = createSlice({
  name: EWhiteList.MODALS,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    openModal: (state: Draft<ModalsState>, action: PayloadAction<IPayloadAction>) => {
      const MODAL_TYPE = action.payload.type;
      state[MODAL_TYPE] = {
        ...(action.payload.data || {}),
        isOpen: true,
      };
    },
    updateModal: (state: Draft<ModalsState>, action: PayloadAction<IPayloadAction>) => {
      const MODAL_TYPE = action.payload.type;
      state[MODAL_TYPE] = {
        ...state[MODAL_TYPE],
        ...(action.payload.data || {}),
        isOpen: true,
      };
    },
    closeModal: (state: Draft<ModalsState>, action: PayloadAction<IPayloadAction>) => {
      const MODAL_TYPE = action.payload.type;
      state[MODAL_TYPE] = initialState[MODAL_TYPE];
    },
  },
});

export const { openModal, updateModal, closeModal } = modalsSlice.actions;

export default modalsSlice.reducer;
