// libs
import { createSelector } from "@reduxjs/toolkit";
// types
import EWhiteList from "src/redux/white-list";
import { RootState } from "src/redux/store";
import { PathClinics } from "./reducer";

const getState = (state: RootState): PathClinics => state[EWhiteList.PATH];

export const pathClinicsSelector = createSelector<RootState, PathClinics, PathClinics>(
  getState,
  (state: PathClinics) => state,
);
