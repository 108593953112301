export const colors = Object.freeze({
  // Greys
  greyDark: "#37393C",
  grey: "#6D7076",
  greyLight: "#C2C8D6",
  greyVeryLight: "#f2f4f8",
  white: "#ffffff",
  //Primary
  main: "#2D6AEF",
  mainLight: "#d9e6ff",
  mainVeryLight: "#eff5ff",
  mainDark: "#0d42b6",
  mainVeryDark: "#08286f",
  // Secondary
  orange: "#f89056",
  orangeLight: "#ffeede",
  orangeVeryLight: "#fff7ef",
  orangeDark: "#e7712f",
  orangeVeryDark: "#aa3f31",
  // UI
  red: "#fb8288",
  redLight: "#ffe9ea",
  redDark: "#ce5157",
  amber: "#ffd747",
  amberLight: "#fff8e0",
  amberDark: "#ef9606",
  green: "#63d683",
  greenLight: "#e8fdee",
  greenDark: "#138b35",
});

export type TColorKeysNames = keyof typeof colors;
