import React from "react";
// hooks
import { useAppDispatch } from "src/hooks";
import { useRouter } from "next/router";
// redux
import { setCoordinates, setDefaultCity, setLocation } from "src/redux/slices/auth";
import { updateVariable } from "src/redux/slices/variables";
import { updatePermission } from "src/redux/slices/permissions";
// const
import { DEFAULT_COORDINATES, IGeometry, LngLatBounds } from "src/constants/coordinates";
import { GEOLOCATION } from "src/constants/permissions";
import { HIDE_GEOLOCATION_MODAL_ON_SEARCH_PAGE } from "src/constants/variables";
// utils
import { getCountry } from "src/utils/countries";
import { getBoundsByCenter } from "src/utils/map";
// types
import { ICountry } from "src/constants/countries";
import { LngLat } from "src/constants/coordinates";

type TonSuccess = (geometry: IGeometry) => void;
type TonError = (geometry: IGeometry) => void;

interface IUseUserGeolocationResponse {
  getUserCoordinates: (onSuccess?: TonSuccess, onError?: TonError) => void;
  getGeolocationPermissionStatus: () => Promise<null | PermissionState>;
  setDefaultCoordinates: () => object;
}

type TUseUserGeolocation = () => IUseUserGeolocationResponse;

export const useUserGeolocation: TUseUserGeolocation = () => {
  const { locale } = useRouter();
  const dispatch = useAppDispatch();

  const userCountry: ICountry = getCountry(locale, "locale") as ICountry;

  const setDefaultCoordinates = () => {
    const center: LngLat = {
      lng: DEFAULT_COORDINATES[userCountry.short_name].center.lng,
      lat: DEFAULT_COORDINATES[userCountry.short_name].center.lat,
    };

    const bounds: LngLatBounds = getBoundsByCenter(center, 10) as LngLatBounds;
    const city = DEFAULT_COORDINATES[userCountry.short_name].name;

    const geometry = { center, bounds, name: "" };
    dispatch(setCoordinates(geometry));
    dispatch(setDefaultCity(city));
    return geometry;
  };

  const setUserAddress = async (center: LngLat) => {
    const geocoder = new google.maps.Geocoder();
    const response = await geocoder.geocode({ location: center });
    const city = response.results.find((item) => item.types.includes("locality"));
    dispatch(setLocation(city?.formatted_address || ""));
    dispatch(updateVariable({ name: HIDE_GEOLOCATION_MODAL_ON_SEARCH_PAGE, value: true }));
  };

  const successCallback = (position: GeolocationPosition, onSuccess?: TonSuccess) => {
    const center: LngLat = {
      lng: position.coords.longitude,
      lat: position.coords.latitude,
    };

    const bounds: LngLatBounds = getBoundsByCenter(center, 13) as LngLatBounds;

    const geometry = { center, bounds, name: "" };
    dispatch(setCoordinates(geometry));
    dispatch(updatePermission({ permission: GEOLOCATION, value: "granted" }));
    setUserAddress(center);
    onSuccess?.(geometry);
  };

  const errorCallback = (positionError?: GeolocationPositionError, onError?: TonError) => {
    const geometry = setDefaultCoordinates();
    dispatch(setCoordinates(geometry));
    dispatch(updatePermission({ permission: GEOLOCATION, value: "denied" }));
    onError?.(geometry);
  };

  const getUserCoordinates = (onSuccess?: TonSuccess, onError?: TonError) => {
    if (navigator?.geolocation) {
      // some systems don't support geolocation
      dispatch(updatePermission({ permission: GEOLOCATION, value: "loading" }));

      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => successCallback(position, onSuccess),
        (positionError: GeolocationPositionError) => errorCallback(positionError, onError),
      );
    } else {
      errorCallback(undefined, onError);
    }
  };

  const getGeolocationPermissionStatus = async () => {
    if (typeof navigator === "undefined") return null;

    const { state } = await navigator.permissions.query({ name: GEOLOCATION });
    dispatch(updatePermission({ permission: GEOLOCATION, value: state }));
    return state;
  };

  return {
    getUserCoordinates,
    getGeolocationPermissionStatus,
    setDefaultCoordinates,
  };
};
