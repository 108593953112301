// libs
// import 'axios-debug-log/enable'
import axios from "axios";

const instance = axios.create({
  baseURL: process.env.API_URL,
  headers: { "Content-Type": "application/json" },
});

// Add a request interceptor
// instance.interceptors.request.use(function (config) {
//   const { accessToken } = getCookies(['accessToken'])
//
//   if (accessToken) {
//     config.headers.Authorization = `Bearer ${accessToken}`
//   }
//
//   return config
// })

export default instance;
