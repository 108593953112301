import amplitude from "amplitude-js";

const instance = "Bloodhound";

export const initAmplitude = () => {
  if (process.browser && process.env.AMPLITUDE_API_KEY) {
    amplitude.getInstance(instance).init(process.env.AMPLITUDE_API_KEY);
  }
};

export const setAmlitudeUserProperties = () => {
  amplitude
    .getInstance(instance)
    .setUserProperties({ referrer: document.referrer, domain: document.domain });
};

export const setAmplitudeUserId = (userId: string) => {
  if (process.browser) {
    amplitude.getInstance(instance).setUserId(userId);
  }
};

export const amplitudeEvent = (name: string, params?: {}) => {
  if (process.browser) {
    amplitude.getInstance(instance).logEvent(name, params);
  }
};

const formattedPathnames: { [pathname: string]: string } = {
  "/": "home",
  "/veterinarian/[...area]": "search_results",
  "/clinics/[...clinic_slug]": "clinic_details",
  "/appointment/request": "request_appointment",
};

export const formatEventName = (pathname: string, section: string, button: string) => {
  const pageName = formattedPathnames[pathname] ?? pathname.replace("/", "");
  const sectionName = section.toLowerCase().replace("/", "").replaceAll(" ", "_");
  const buttonName = button.toLowerCase().replace("/", "").replaceAll(" ", "_");

  return `${pageName}_${sectionName}_${buttonName}_clicked`;
};
