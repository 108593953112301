// libs
import { createSelector } from "@reduxjs/toolkit";
// types
import EWhiteList from "src/redux/white-list";
import { RootState } from "src/redux/store";
import { AuthState } from "./reducer";

type TAuthState = RootState[EWhiteList.AUTH];
type TAuthUser = TAuthState["user"];
type TAuthUserGeometry = TAuthState["location"];

const getState = (state: RootState): TAuthState => state[EWhiteList.AUTH];

export const authSelector = createSelector<RootState, TAuthState, TAuthState>(
  getState,
  (state: AuthState) => state,
);

export const authUserSelector = createSelector<RootState, TAuthState, TAuthUser>(
  getState,
  (state: AuthState) => state.user,
);

export const authUserGeometrySelector = createSelector<RootState, TAuthState, TAuthUserGeometry>(
  getState,
  (state: AuthState) => state.geometry,
);

export const authUserLocationSelector = createSelector<RootState, TAuthState, TAuthUserGeometry>(
  getState,
  (state: AuthState) => state.location,
);
