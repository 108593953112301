// const
import { ICountry, COUNTRIES } from "src/constants/countries";

export const getCountry = (
  field: string | undefined,
  searchBy: keyof ICountry,
): ICountry | undefined => {
  if (!field) return undefined;

  return COUNTRIES.find((country: ICountry) => country[searchBy].includes(field));
};
