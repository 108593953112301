// libs
import styled, { css } from "styled-components";
// theme
import { colors, typography } from "src/theme";
// types
import { EVariants } from "src/constants/variants";
import device from "src/constants/media";

const baseStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${typography.fontFamily};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  border-radius: 8px;
  border: none;
  padding: 14px;
  cursor: pointer;
  position: relative;
  height: 48px;
  transition: all 200ms ease-in-out;
  width: 100%;

  @media ${device.mobileL} {
    width: fit-content;
  }
`;

const primaryStyles = css`
  color: ${colors.white};
  background-color: ${colors.main};
  :hover {
    background-color: ${colors.mainDark};
  }
`;

const secondaryStyles = css<{ isActive?: boolean; isToggle?: boolean }>`
  color: ${colors.greyDark};
  border: 2px solid ${colors.greyVeryLight};
  background-color: ${colors.white};
  :hover {
    background-color: ${colors.mainVeryLight};
  }
`;

const tertiaryStyles = css`
  color: ${colors.white};
  background-color: ${colors.orange};
  :hover {
    background-color: ${colors.orangeDark};
  }
`;

const quaternaryStyles = css`
  color: ${colors.white};
  border: 2px solid ${colors.white};
  background-color: transparent;
  :hover {
    background-color: ${colors.mainDark};
  }
`;

export const Button = styled.button<{ variant: EVariants }>`
  ${baseStyles};

  ${({ variant }) => variant === EVariants.PRIMARY && primaryStyles};
  ${({ variant }) => variant === EVariants.SECONDARY && secondaryStyles};
  ${({ variant }) => variant === EVariants.TERTIARY && tertiaryStyles};
  ${({ variant }) => variant === EVariants.QUATERNARY && quaternaryStyles};
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  width: 100%;
  padding-inline: 12px;

  @media ${device.mobileL} {
    place-content: center;
    flex-direction: row;
    padding: 0;
  }
`;

export default Button;
