// libs
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
// const
import * as DYNAMIC_FIELDS from "src/constants/dynamic";
// types
import EWhiteList from "src/redux/white-list";

// Define a type for the slice state
export interface DynamicState {
  [key: string]: null | unknown;
}

const initialState: DynamicState = {};

Object.keys(DYNAMIC_FIELDS).forEach((key: string) => {
  initialState[key] = null;
});

interface IPayloadAction {
  key: string;
  value: any;
}

export const dynamicSlice = createSlice({
  name: EWhiteList.DYNAMIC,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setDynamicState: (state: Draft<DynamicState>, action: PayloadAction<DynamicState>) => {
      state = action.payload;
    },
    bulkSetState: (state: Draft<DynamicState>, action: PayloadAction<DynamicState>) => {
      const keys = Object.keys(action.payload);
      keys.forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    setDynamicStateByKey: (state: Draft<DynamicState>, action: PayloadAction<IPayloadAction>) => {
      const key = action.payload.key;
      // @ts-ignore
      state[key] = action.payload.value;
    },
  },
});

export const { setDynamicState, setDynamicStateByKey, bulkSetState } = dynamicSlice.actions;

export default dynamicSlice.reducer;
