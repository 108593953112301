// eslint-disable-next-line import/prefer-default-export
export const ENDPOINTS = {
  GET: {
    clinic: (id: number | string) => `/clinics/${id}`,
    nearestClinicDetails: "/clinics/near_point",
    // eslint-disable-next-line max-len
    // EXAMPLE: /clinics/filtered?_start=100&ne_lat=41.7067&ne_lng=-75.1783&nw_lat=41.7067&nw_lng=-92.9921&se_lat=34.8491&se_lng=-75.1783&sw_lat=34.8491&sw_lng=-92.9921
    searchClinics: "/clinics/filtered",
    clinics: "/clinics",
    clinicsCount: "/clinics/count",
    searchClinicsCount: "/clinics/filtered/count",
    searchClinicsGeo: "/clinics/filtered_by_url",
    searchClinicsGeoCount: "/clinics/filtered_by_url/count",
    getClinicsByCity: "/clinics/get_by_city",

    service: (id: number | string) => `/services/${id}`,
    services: "/services",
    servicesCount: "/services/count",

    reviews: "/reviews",
    reviewsCount: "/reviews/count",
    reviewsStatistic: "/reviews_statistic",

    species: "/species-items",

    dynamic: {
      // common
      footer: "/footer",
      header: "/header",
      core: "/core",
      storesRating: "/stores-rating-block",
      meta: "/meta-tags",

      // home
      registerYourPet: "/register-your-pet",

      // download
      getTheApp: "/get-the-app-block",

      // list-your-clinic
      howPetsAppStacksUp: "/how-pets-app-stacks-up",
      listYourClinic: "/list-your-clinic",
      whyListYourClinic: "/why-list-your-clinic-on-pets-app",
      morePetsApp: "/even-more-to-pets-app",
      customerEngagement: "/want-to-improve-customer-engagement",

      // clinics
      howItWorks: "/how-it-works-block",
      nextWebinar: "/next-webinar",
      whyPetsApp: "/why-petsapp",
      usedBy: "/used-by",
      whatHaveToSay: "/what-clinics-have-to-say",
      yourClinicsApp: "/your-clinics-app",

      // demo
      demo: "/find-out-how-we-can-help",
    },
  },
  POST: {
    appointment: {
      request: "/appointment-requests",
    },
    leaveReview: "/reviews",
    clinicRequest: "/list-clinic-requests",
    claimYourClinic: "/claim-your-clinics",
    download: "/v1/public/guest/invite",
  },
  PUT: {},
  DELETE: {},
};
