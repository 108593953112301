import { getCountryZoom, zoom } from "src/constants/map";
import { ParsedUrlQuery } from "querystring";

const formatPlace = (place: string) =>
  place
    .toLowerCase()
    .replace(/ - |\//g, " ")
    .replace(/ |\//g, "-");

export const formatSearchUrl = (
  state: string | undefined,
  city: string | undefined,
  area: string | undefined,
): string => {
  let url = "/veterinarian";

  if (state) url += `/${formatPlace(state)}`;
  if (city) url += `/${formatPlace(city)}`;
  if (area) url += `/${formatPlace(area)}`;

  return url;
};

export const formatSearchZoom = (
  shortCountryName: string,
  state: string | undefined,
  city: string | undefined,
  area: string | undefined,
): number => {
  if (area) return zoom.area;
  if (city) return zoom.city;
  if (state) return zoom.state;

  return getCountryZoom(shortCountryName);
};

export const getStringParams = (params: ParsedUrlQuery) => {
  const plainArr = Object.entries(params).reduce((acc, [key, value]) => {
    // key = services
    // value = 'param_1' OR ['param_1', 'param_2']
    if (Array.isArray(value)) {
      return [...acc, ...value.map((param) => `${key}=${param}`)];
    }
    return [...acc, `${key}=${value}`];
  }, [] as string[]);

  return plainArr.join("&");
};

export const formatSearchArea = (area: string[]) =>
  area &&
  area
    .reverse()
    .join(", ")
    .replace(/_/g, " ")
    .replace(/-/g, " ")
    .split(" ")
    .map((el) => el.substring(0, 1).toUpperCase() + el.substring(1))
    .join(" ");
