interface IZoom {
  country: {
    [key: string]: number;
  };
  state: number;
  city: number;
  area: number;
  service: number;
  def: number;
}

export const zoom: IZoom = {
  country: {
    US: 3,
    CA: 3,
    IE: 5,
    UK: 3.9,
  },
  state: 5,
  city: 10,
  area: 12,
  def: 10,

  // https://docs.mapbox.com/help/glossary/zoom-level/
  // 12.3 lvl = 15.2 meters/px * 670px(wide) = 10 184m (longitude) OR 10184 / 2 = 5092m from the center to map border
  service: 12.3,
};

export const getCountryZoom = (shortCountryName: string): number => zoom.country[shortCountryName];

export enum COUNTRY_TYPES {
  COUNTRY = "country",
}

export enum STATE_TYPES {
  ADMIN_AREA_1 = "administrative_area_level_1",
}

export enum CITY_TYPES {
  LOCALITY = "locality",
  POSTAL_TOWN = "postal_town",
}

export enum COUNTY_TYPES {
  ADMIN_AREA_2 = "administrative_area_level_2",
  ADMIN_AREA_3 = "administrative_area_level_3",
  ADMIN_AREA_4 = "administrative_area_level_4",
}

export enum AREA_TYPES {
  SUBLOCALITY = "sublocality",
  SUBLOCALITY_1 = "sublocality_level_1",
  POSTAL_CODE = "postal_code",
}

export const PLACE_TYPES = {
  ...COUNTRY_TYPES,
  ...STATE_TYPES,
  ...CITY_TYPES,
  ...COUNTY_TYPES,
  ...AREA_TYPES,
  NATURAL_FEATURE: "natural_feature",
};

export interface IAddressItem {
  short_name: string;
  long_name: string;
  types: string[];
}

export interface IAddress {
  country: IAddressItem | undefined;
  city: IAddressItem | undefined;
  state: IAddressItem | undefined;
  area: IAddressItem | undefined;
}
