import device from "src/constants/media";
import styled, { css } from "styled-components";
import { colors } from "../theme";

interface BaseTypography {
  textColor?: string;
  color?: never;
}

interface IAction extends BaseTypography {
  as?: string;
}

export const regular = 500;
export const bold = 600;

export const remCalc = (val: number) => `${val / 16}rem`;

export const fontFamily: string =
  "Brown, Avenir Next, Avenir, -apple-system, BlinkMacSystemFont, Roboto, system-font, sans-serif";

export const displayFontFamily: string = 'Cooper, "Bodoni 72", Georgia, serif';

const baseTypography = css<BaseTypography>`
  font-family: ${fontFamily};
  height: min-content;
  margin: 0;
  color: ${({ textColor }) => textColor || colors.greyDark};
  position: relative;
`;

export const Jumbo = styled.h1`
  ${baseTypography};
  font-family: ${displayFontFamily};
  font-weight: ${regular};
  font-size: ${remCalc(36)};
  line-height: ${remCalc(44)};

  @media ${device.mobileL} {
    font-size: ${remCalc(48)};
    line-height: ${remCalc(56)};
  }

  @media ${device.tablet} {
    font-size: ${remCalc(64)};
    line-height: ${remCalc(72)};
  }
`;

export const LargeTitle = styled.h2`
  ${baseTypography};
  font-family: ${displayFontFamily};
  font-size: ${remCalc(24)};
  font-weight: ${regular};
  line-height: ${remCalc(32)};

  @media ${device.mobileM} {
    font-size: ${remCalc(36)};
    line-height: ${remCalc(44)};
  }
`;

export const Title = styled.h2`
  ${baseTypography};
  font-size: ${remCalc(20)};
  font-weight: ${bold};
  line-height: ${remCalc(28)};

  @media ${device.mobileM} {
    font-size: ${remCalc(24)};
    line-height: ${remCalc(32)};
  }
`;

export const Callout = styled.p`
  ${baseTypography};
  font-size: ${remCalc(16)};
  font-weight: ${regular};
  line-height: ${remCalc(20)};

  @media ${device.mobileS} {
    font-size: ${remCalc(20)};
    line-height: ${remCalc(28)};
  }

  @media ${device.mobileM} {
    font-size: ${remCalc(24)};
    line-height: ${remCalc(32)};
  }
`;

export const Headline = styled.p`
  ${baseTypography};
  font-size: ${remCalc(20)};
  font-weight: ${bold};
  line-height: ${remCalc(28)};
`;

export const Body = styled.p`
  ${baseTypography};
  font-size: ${remCalc(20)};
  font-weight: ${regular};
  line-height: ${remCalc(28)};
  color: ${({ textColor }) => textColor || colors.grey};
`;

export const Subhead = styled.p`
  ${baseTypography};
  font-size: ${remCalc(16)};
  font-weight: ${bold};
  line-height: ${remCalc(20)};
  color: ${({ textColor }) => textColor || colors.grey};
`;

export const Caption = styled.p`
  ${baseTypography};
  font-size: ${remCalc(16)};
  font-weight: ${regular};
  line-height: ${remCalc(20)};
  color: ${({ textColor }) => textColor || colors.grey};
`;

export const Footnote = styled.p`
  ${baseTypography};
  font-size: ${remCalc(14)};
  font-weight: ${regular};
  line-height: ${remCalc(20)};
  color: ${({ textColor }) => textColor || colors.grey};
`;

export const Action = styled.p<IAction>`
  ${baseTypography};
  font-size: ${remCalc(16)};
  font-weight: ${bold};
  line-height: ${remCalc(20)};

  ${({ as }) =>
    as === "button" &&
    css`
      border: none;
      background: none;
      cursor: pointer;
      margin: 0;
      padding: 0;

      :hover {
        color: ${colors.mainDark};
      }
    `}
`;

export const TextContainer = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || "12px"};
  place-items: center;
`;

export default { fontFamily, displayFontFamily };
