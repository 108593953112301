// libs
import { createSelector } from "@reduxjs/toolkit";
// types
import EWhiteList from "src/redux/white-list";
import { RootState } from "src/redux/store";

type TAppointmentState = RootState[EWhiteList.APPOINTMENT];
type TRequestState = TAppointmentState["request"];
type TBookState = TAppointmentState["book"];

const getState = (state: RootState): TAppointmentState => state[EWhiteList.APPOINTMENT];

export const appointmentStateSelector = createSelector<
  RootState,
  TAppointmentState,
  TAppointmentState
>(getState, (state: TAppointmentState) => state);

export const appointmentRequestSelector = createSelector<
  RootState,
  TAppointmentState,
  TRequestState
>(getState, (state: TAppointmentState) => state.request);

export const appointmentBookSelector = createSelector<RootState, TAppointmentState, TBookState>(
  getState,
  (state: TAppointmentState) => state.book,
);
