/* White list data keys stores in async storage */
enum WhiteList {
  AUTH = "AUTH",
  MODALS = "MODALS",
  APPOINTMENT = "APPOINTMENT",
  PERMISSIONS = "PERMISSIONS",
  REVIEW = "REVIEW",
  VARIABLES = "VARIABLES",
  DYNAMIC = "DYNAMIC",
  PATH = "PATH",
}

export default WhiteList;
