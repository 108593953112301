// redux keys fo DYNAMIC field
// common
export const META: string = "META";
export const HEADER: string = "HEADER";
export const FOOTER: string = "FOOTER";
export const CORE: string = "CORE";
export const STORES_RATING_BLOCK: string = "STORES_RATING_BLOCK";

// home
export const REGISTER_YOUR_PET_BLOCK: string = "REGISTER_YOUR_PET_BLOCK";

// download
export const GET_THE_APP_BLOCK: string = "GET_THE_APP_BLOCK";

// list-your-clinic
export const LIST_YOUR_CLINIC_BLOCK: string = "LIST_YOUR_CLINIC_BLOCK";
export const WHY_LIST_YOUR_CLINIC_BLOCK: string = "WHY_LIST_YOUR_CLINIC_BLOCK";
export const MORE_PETS_APP_BLOCK: string = "MORE_PETS_APP_BLOCK";
export const CUSTOMER_ENGAGEMENT_BLOCK: string = "CUSTOMER_ENGAGEMENT_BLOCK";

// clinics
export const NEXT_WEBINAR_BLOCK: string = "NEXT_WEBINAR_BLOCK";
export const HOW_IT_WORKS_BLOCK: string = "HOW_IT_WORKS_BLOCK";
export const WHY_PETS_APP_BLOCK: string = "WHY_PETS_APP_BLOCK";
export const HOW_PETS_APP_STACKS_UP_BLOCK: string = "HOW_PETS_APP_STACKS_UP_BLOCK";
export const USED_BY_BLOCK: string = "USED_BY_BLOCK";
export const WHAT_HAVE_TO_SAY: string = "WHAT_HAVE_TO_SAY";
export const YOUR_CLINICS_APP_BLOCK: string = "YOUR_CLINICS_APP_BLOCK";

// demo
export const DEMO_BLOCK: string = "DEMO_BLOCK";
