// libs
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
// const
import { DEFAULT_COORDINATES, IGeometry } from "src/constants/coordinates";
// types
import type { IUser } from "src/types";
import EWhiteList from "src/redux/white-list";

// Define a type for the slice state
export interface AuthState {
  user: IUser | null;
  isLoading: boolean;
  isLogin: boolean;
  error: string | null;
  geometry: IGeometry;
  location: string | null;
  defaultCity: string | null;
}

// Define the initial state using that type
const initialState: AuthState = {
  user: null,
  isLoading: false,
  isLogin: false,
  error: null,
  geometry: DEFAULT_COORDINATES.US,
  location: null,
  defaultCity: null,
};

export const authSlice = createSlice({
  name: EWhiteList.AUTH,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAuth: (state: Draft<AuthState>, action: PayloadAction<AuthState>) => {
      state = action.payload;
    },
    clearAuth: (state: Draft<AuthState>) => {
      state = initialState;
    },
    setDefaultCity: (state: Draft<AuthState>, action: PayloadAction<string>) => {
      state.defaultCity = action.payload;
    },
    setCoordinates: (state: Draft<AuthState>, action: PayloadAction<IGeometry>) => {
      state.geometry = action.payload;
    },
    setCoordinatesCenter: (state: Draft<AuthState>, action: PayloadAction<IGeometry["center"]>) => {
      state.geometry.center = action.payload;
    },
    setCoordinatesBounds: (state: Draft<AuthState>, action: PayloadAction<IGeometry["bounds"]>) => {
      state.geometry.bounds = action.payload;
    },
    setLocation: (state: Draft<AuthState>, action: PayloadAction<string>) => {
      state.location = action.payload;
    },
  },
});

export const {
  setAuth,
  clearAuth,
  setCoordinates,
  setCoordinatesCenter,
  setCoordinatesBounds,
  setLocation,
  setDefaultCity,
} = authSlice.actions;

export default authSlice.reducer;
