// libs
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
// const
import * as variables from "src/constants/variables";
// types
import EWhiteList from "src/redux/white-list";

// Define a type for the slice state
export interface VariablesState {
  [key: string]: boolean;
}

// Define the initial state using that type
const initialState: VariablesState = {};

Object.values(variables).forEach((key: string) => {
  initialState[key] = false;
});

interface IPayloadAction {
  name: string;
  value: boolean;
}

export const variablesSlice = createSlice({
  name: EWhiteList.VARIABLES,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateVariable: (state: Draft<VariablesState>, action: PayloadAction<IPayloadAction>) => {
      const CONST_NAME = action.payload.name;
      state[CONST_NAME] = action.payload.value;
    },
  },
});

export const { updateVariable } = variablesSlice.actions;

export default variablesSlice.reducer;
