// libs
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
// types
import type { TClinicDetails } from "src/types";
import EWhiteList from "src/redux/white-list";

// Define a type for the slice state
export interface ReviewState {
  step: number;
  clinic: TClinicDetails | null;
}

// Define the initial state using that type
const initialState: ReviewState = {
  step: 0,
  clinic: null,
};

export const reviewSlice = createSlice({
  name: EWhiteList.REVIEW,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearReviewState: (state: Draft<ReviewState>) => {
      state.step = initialState.step;
      state.clinic = initialState.clinic;
    },
    setReviewStep: (state: Draft<ReviewState>, action: PayloadAction<ReviewState["step"]>) => {
      state.step = action.payload;
    },
    setReviewClinic: (state: Draft<ReviewState>, action: PayloadAction<ReviewState["clinic"]>) => {
      state.clinic = action.payload;
    },
  },
});

export const { clearReviewState, setReviewStep, setReviewClinic } = reviewSlice.actions;

export default reviewSlice.reducer;
