// libs
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
// types
import type { TClinicNearest } from "src/types";
import EWhiteList from "src/redux/white-list";
import { IRating } from "src/types";

// Define a type for the slice state
export interface AppointmentState {
  request: RequestState;
  book: BookState;
}

export interface RequestState {
  step: number;
  clinic: TClinicNearest | null;
  rating: IRating["rating"];
  date: string;
  time: string;
  form: null | { [key: string]: any };
  distanceText: boolean;
}

export interface BookState {
  step: number;
}

const initialRequestState: RequestState = {
  step: 0,
  clinic: null,
  rating: [],
  date: DateTime.fromJSDate(new Date()).toFormat("dd/LL/yyyy"),
  time: "Morning",
  form: null,
  distanceText: false,
};

const initialBookState: BookState = {
  step: 0,
};

const initialState: AppointmentState = {
  request: initialRequestState,
  book: initialBookState,
};

export const appointmentSlice = createSlice({
  name: EWhiteList.APPOINTMENT,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // request
    clearRequestState: (state: Draft<AppointmentState>) => {
      state.request = initialRequestState;
    },
    setRequestState: (state: Draft<AppointmentState>, action: PayloadAction<RequestState>) => {
      state.request = action.payload;
    },
    setRequestStep: (
      state: Draft<AppointmentState>,
      action: PayloadAction<{ step: RequestState["step"] }>,
    ) => {
      state.request.step = action.payload.step;
    },
    setRequestClinicDetails: (
      state: Draft<AppointmentState>,
      action: PayloadAction<{ clinic: RequestState["clinic"] }>,
    ) => {
      state.request.clinic = action.payload.clinic;
    },
    setRequestRating: (
      state: Draft<AppointmentState>,
      action: PayloadAction<{ rating: RequestState["rating"] }>,
    ) => {
      state.request.rating = action.payload.rating;
    },
    setRequestDate: (
      state: Draft<AppointmentState>,
      action: PayloadAction<{ date: RequestState["date"] }>,
    ) => {
      state.request.date = action.payload.date;
    },
    setRequestTime: (
      state: Draft<AppointmentState>,
      action: PayloadAction<{ time: RequestState["time"] }>,
    ) => {
      state.request.time = action.payload.time;
    },
    setRequestForm: (
      state: Draft<AppointmentState>,
      action: PayloadAction<{ form: RequestState["form"] }>,
    ) => {
      state.request.form = action.payload.form;
    },
    setRequestDistanceText: (
      state: Draft<AppointmentState>,
      action: PayloadAction<{ distanceText: RequestState["distanceText"] }>,
    ) => {
      state.request.distanceText = action.payload.distanceText;
    },

    // book
    clearBookState: (state: Draft<AppointmentState>) => {
      state.book = initialBookState;
    },
    setBookState: (state: Draft<AppointmentState>, action: PayloadAction<BookState>) => {
      state.book = action.payload;
    },
  },
});

export const {
  // request
  clearRequestState,
  setRequestState,
  setRequestStep,
  setRequestClinicDetails,
  setRequestRating,
  setRequestDate,
  setRequestTime,
  setRequestForm,
  setRequestDistanceText,

  // book
  clearBookState,
  setBookState,
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
