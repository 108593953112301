// libs
import { createSelector } from "@reduxjs/toolkit";
// types
import EWhiteList from "src/redux/white-list";
import { RootState } from "src/redux/store";
import { ReviewState } from "./reducer";

const getState = (state: RootState): ReviewState => state[EWhiteList.REVIEW];

export const reviewSelector = createSelector<RootState, ReviewState, ReviewState>(
  getState,
  (state: ReviewState) => state,
);
