// libs
import { createSelector } from "@reduxjs/toolkit";
// const
import { META } from "src/constants/dynamic";
// types
import EWhiteList from "src/redux/white-list";
import { RootState } from "src/redux/store";
import { IDynamicMeta } from "src/types/dynamic/meta";

type TDynamicState = RootState[EWhiteList.DYNAMIC];

const getState = (state: RootState): TDynamicState => state[EWhiteList.DYNAMIC];

export const dynamicStateSelector = createSelector<RootState, TDynamicState, TDynamicState>(
  getState,
  (state: TDynamicState) => state,
);

export const dynamicStateFieldSelector = <T = any>(field: string) =>
  createSelector<RootState, TDynamicState, T>(getState, (state: TDynamicState) => {
    return state[field];
  });

export const dynamicMetaRouteSelector = (route: string) =>
  createSelector<RootState, TDynamicState, IDynamicMeta>(
    getState,
    (state: TDynamicState) =>
      (state[META] || []).find((item: IDynamicMeta) => item.route === route) || {},
  );
