export interface LngLat {
  lng: number;
  lat: number;
}

export interface LngLatBounds {
  ne: LngLat;
  sw: LngLat;
}

export interface IGeometry {
  name: string;
  center: LngLat;
  bounds: LngLatBounds;
}

// zoom is 13
const DEFAULT_CITIES = {
  // less zoom for ny
  NEW_YORK: {
    name: "New York",
    center: { lng: -74.0059728, lat: 40.7127753 },
    bounds: {
      sw: { lng: -74.1433, lat: 40.6347 },
      ne: { lng: -73.8686, lat: 40.7908 },
    },
  },
  LONDON: {
    name: "London",
    center: { lng: -0.1277583, lat: 51.5073509 },
    bounds: {
      sw: { lng: -0.1449, lat: 51.4993 },
      ne: { lng: -0.1105, lat: 51.5153 },
    },
  },
  TORONTO: {
    name: "Toronto",
    center: { lng: -79.4004, lat: 43.6439 },
    bounds: {
      sw: { lng: -75.7143, lat: 45.4124 },
      ne: { lng: -79.366, lat: 43.6625 },
    },
  },
  DUBLIN: {
    name: "Dublin",
    center: { lng: -6.2603097, lat: 53.3498053 },
    bounds: {
      sw: { lng: -6.2774, lat: 53.3421 },
      ne: { lng: -6.2431, lat: 53.3574 },
    },
  },
};

interface IDefaultCoordinates {
  US: IGeometry;
  UK: IGeometry;
  CA: IGeometry;
  IE: IGeometry;
  [key: string]: IGeometry;
}

export const DEFAULT_COORDINATES: IDefaultCoordinates = {
  US: DEFAULT_CITIES.NEW_YORK,
  UK: DEFAULT_CITIES.LONDON,
  CA: DEFAULT_CITIES.TORONTO,
  IE: DEFAULT_CITIES.DUBLIN,
};
