export const sizesMedia = {
  mobileS: 300,
  mobileM: 380,
  mobileL: 480,
  tabletS: 550,
  tablet: 700,
  laptop: 1000,
  laptopS: 1180,
  laptopL: 1400,
  desktop: 2500,
};

const sizePixels = {
  mobileS: `${sizesMedia.mobileS}px`,
  mobileM: `${sizesMedia.mobileM}px`,
  mobileL: `${sizesMedia.mobileL}px`,
  tabletS: `${sizesMedia.tabletS}px`,
  tablet: `${sizesMedia.tablet}px`,
  laptop: `${sizesMedia.laptop}px`,
  laptopS: `${sizesMedia.laptopS}px`,
  laptopL: `${sizesMedia.laptopL}px`,
  desktop: `${sizesMedia.mobileS}px`,
};

const device = {
  mobileS: `(min-width: ${sizePixels.mobileS})`,
  mobileM: `(min-width: ${sizePixels.mobileM})`,
  mobileL: `(min-width: ${sizePixels.mobileL})`,
  tabletS: `(min-width: ${sizePixels.tabletS})`,
  tablet: `(min-width: ${sizePixels.tablet})`,
  laptop: `(min-width: ${sizePixels.laptop})`,
  laptopS: `(min-width: ${sizePixels.laptopS})`,
  laptopL: `(min-width: ${sizePixels.laptopL})`,
  desktop: `(min-width: ${sizePixels.desktop})`,
  desktopL: `(min-width: ${sizePixels.desktop})`,
};

export default device;
