export interface ICountry {
  name: string;
  short_name: string;
  locale: string[];
}

export const COUNTRIES: ICountry[] = [
  {
    name: "United States",
    short_name: "US",
    locale: ["en-US"],
  },
  {
    name: "United Kingdom",
    short_name: "UK",
    locale: ["en-GB"],
  },
  {
    name: "Canada",
    short_name: "CA",
    locale: ["en-CA", "fr-CA"],
  },
  {
    name: "Ireland",
    short_name: "IE",
    locale: ["en-IE"],
  },
];
