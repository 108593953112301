// libs
import { createSelector } from "@reduxjs/toolkit";
// types
import EWhiteList from "src/redux/white-list";
import { RootState } from "src/redux/store";
import { PermissionsStore } from "./reducer";

const getState = (state: RootState): PermissionsStore => state[EWhiteList.PERMISSIONS];

export const permissionSelector = (permission: PermissionName) =>
  createSelector<RootState, PermissionsStore, string | null>(
    getState,
    (state: PermissionsStore) => state[permission],
  );
