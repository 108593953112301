// libs
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
// const
import * as permissions from "src/constants/permissions";
// types
import EWhiteList from "src/redux/white-list";

// Define a type for the slice state
export interface PermissionsStore {
  [key: string]: PermissionState | "loading" | null;
}

// Define the initial state using that type
const initialState: PermissionsStore = {};

Object.values(permissions).forEach((key: string) => {
  initialState[key] = null;
});

interface IPayloadAction {
  permission: string;
  value: PermissionState | "loading" | null;
}

export const permissionsSlice = createSlice({
  name: EWhiteList.PERMISSIONS,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updatePermission: (state: Draft<PermissionsStore>, action: PayloadAction<IPayloadAction>) => {
      const PERMISSION_NAME = action.payload.permission;
      state[PERMISSION_NAME] = action.payload.value;
    },
  },
});

export const { updatePermission } = permissionsSlice.actions;

export default permissionsSlice.reducer;
