// libs
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
// types
import EWhiteList from "src/redux/white-list";

// Define a type for the slice state
export interface PathClinics {
  path: string;
}

// Define the initial state using that type
const initialState: PathClinics = {
  path: "",
};

export const pathClinics = createSlice({
  name: EWhiteList.REVIEW,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPathClinics: (state: Draft<PathClinics>, action: PayloadAction<PathClinics["path"]>) => {
      state.path = action.payload;
    },
  },
});

export const { setPathClinics } = pathClinics.actions;

export default pathClinics.reducer;
