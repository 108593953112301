// libs
import { createSelector } from "@reduxjs/toolkit";
// types
import EWhiteList from "src/redux/white-list";
import { RootState } from "src/redux/store";
import { VariablesState } from "./reducer";

const getState = (state: RootState): VariablesState => state[EWhiteList.VARIABLES];

export const variablesStateSelector = createSelector<RootState, VariablesState, VariablesState>(
  getState,
  (state: VariablesState) => state,
);

export const variablesStateKeySelector = (key: string) =>
  createSelector<RootState, VariablesState, boolean>(
    getState,
    (state: VariablesState) => state[key],
  );
